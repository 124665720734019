<h3 mat-dialog-title class="text-center text-uppercase mt-0 mb-4">{{data.title}} Merged Fields</h3>
<div mat-dialog-content class="mt-2 mb-2">
  <div class="row">
    <ng-container *ngFor="let field of mergedFields; index as i;">
      <div class="col-12 d-flex justify-content-between">
        <span class="mr-2">{{ field.key }}: </span>
        <pre><span class="ml-2 wrap-text">{{ field.value }}</span></pre>
      </div>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions>
  <button type="button" class="btn btn-warning btn-round ml-auto" (click)="onClose()"> Close </button>
</div>
