<div class="row">
  <form class="col-12" [formGroup]="humanForm">
    <div class="row">
      <mat-form-field class="col-12 col-md-3" appearance="outline">
        <mat-label> First Name </mat-label>
        <input type="text" placeholder="First Name" matInput formControlName="firstName">
      </mat-form-field>
      <mat-form-field class="col-12 col-md-3" appearance="outline">
        <mat-label> Middle Name </mat-label>
        <input type="text" placeholder="Middle Name" matInput formControlName="middleName">
      </mat-form-field>
      <mat-form-field class="col-12 col-md-3" appearance="outline">
        <mat-label> Last Name </mat-label>
        <input type="text" placeholder="Last Name" matInput formControlName="lastName">
      </mat-form-field>
      <mat-form-field class="col-12 col-md-3" appearance="outline">
        <mat-label> Email </mat-label>
        <input type="text" placeholder="Email" matInput formControlName="email">
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6" appearance="outline">
        <mat-label> Country </mat-label>
        <input type="text" placeholder="Country" matInput formControlName="country">
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6" appearance="outline">
        <mat-label> State </mat-label>
        <input type="text" placeholder="State" matInput formControlName="state">
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6" appearance="outline">
        <mat-label> City </mat-label>
        <input type="text" placeholder="City" matInput formControlName="city">
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6" appearance="outline">
        <mat-label> Address </mat-label>
        <input type="text" placeholder="Address" matInput formControlName="address">
      </mat-form-field>
    </div>
  </form>
</div>