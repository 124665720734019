<h3 mat-dialog-title class="text-center text-uppercase">Search Human</h3>
<div mat-dialog-content>
  <div class="row">
    <form class="col-12" [formGroup]="humanForm">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <mat-form-field class="col-12" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput type="email" placeholder="Email" formControlName="email" />
              <button mat-icon-button matSuffix type="button" (click)="onSearchHuman()">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field class="col-12" appearance="outline">
          <mat-label> First Name </mat-label>
          <input matInput type="text" placeholder="First Name" formControlName="firstName" />
        </mat-form-field>
        <mat-form-field class="col-12" appearance="outline">
          <mat-label> Last Name </mat-label>
          <input matInput type="text" placeholder="Last Name" formControlName="lastName" />
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-around align-items-center">
  <button type="button" class="btn btn-danger btn-round btn-fab" (click)="onCloseDialog()">
    <i class="material-icons">close</i>
  </button>
  <button type="button" class="btn btn-success btn-round btn-fab" (click)="onSelectHuman()">
    <i class="material-icons">done</i>
  </button>
</div>
