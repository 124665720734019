<h3 mat-dialog-title>Confirm</h3>
<div mat-dialog-content class="text-center py-1">
  <span class="font-weight-bold"> {{ data.confirmMsg }} </span>
</div>
<div mat-dialog-actions class="d-flex justify-content-around align-items-center">
  <button mat-raised-button color="primary" (click)="onConfirm()">
    {{ data.confirmBtnTxt }}
  </button>
  <button mat-raised-button color="warn" (click)="onDecline()">
    {{ data.declineBtnTxt }}
  </button>
</div>
