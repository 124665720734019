<div class="wrapper">
  <div class="sidebar" data-color="white" data-background-color="black">
    <app-sidebar-cmp></app-sidebar-cmp>
    <div class="sidebar-background" style="background-image: url(assets/img/sidebar-bg.jpg)"></div>
  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>
    <router-outlet></router-outlet>
  </div>

  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#ea7900"
    type="line-scale"
    [fullScreen]="false"
  >
  </ngx-spinner>
</div>

<ng-template cdk-portal #docViewerPortal="cdkPortal">
  <universal-doc-viewer
    [doc]="doc"
    (closeViewer)="disposeOverlay()"
    (loadError)="onLoadDocError()"
  ></universal-doc-viewer>
</ng-template>

<ng-template cdk-portal #pdfViewerPortal="cdkPortal">
  <universal-pdf-viewer [pdf]="pdf" (closeViewer)="disposeOverlay()" (loadError)="onLoadPdfError()">
  </universal-pdf-viewer>
</ng-template>
