import { Sku, TaxDocNames, TaxProductStatus, TemplateProductName } from "../enums";

export const TAX_SETTLEMENT_FIELDS = {
  amountDue: "payOffAmount",
  irsAccount: "irsAccountNum",
  taxYear: "taxYear",
  mailingDate2: "mailingDate2",
  irsTechCertifiedMailNumber: "irsTechDeptCertifiedMailNum",
  // treasuryDeptCertifiedMailNumber: "treasuryDeptCertifiedMailNum",
  treasurySectaryCertifiedMailNumber: "treasurySecretaryCertifiedMailNum",
  irsPublicCertifiedMailNumber: "irsPubCertifiedMailNum",
  irsPrivateRegisteredMailNumber: "irsPriRegisteredMailNum",
  ttbExciseTax: "ttbExciseTax",
  // treasuryRegisteredMailNumber: "treasuryRegisteredMailNum",
  irsPublicAddress: "irsPubAddr",
  irsPublicCity: "irsPubCity",
  irsPublicState: "irsPubState",
  irsPublicZip: "irsPubZipCode",
  irsPuName: "irsPubDepartName",
  irsPrivateAddress: "irsPriAddr",
  irsPrivateCity: "irsPriCity",
  irsPrivateState: "irsPriState",
  irsPrivateZip: "irsPriZipCode",
  irsPName: "irsPriDepartName",
  // notaryAddress: "notaryAddr",
  // notaryName: "notaryName",
  // notaryCity: "notaryCity",
  // notaryState: "notaryState",
  // notaryZip: "notaryZip",
  // pib1099Date: "pib1099Date",
  // boe1099Date: "boe1099Date",
  date1096: "date1096",
  refNum1096: "refNum1096",
  es1040CertMailNum: "es1040CertMailNum",
  v1040CertMailNum: "v1040CertMailNum",
  pibRegMailNum: "pibRegMailNum",
  boeRegMailNum: "boeRegMailNum",
  datePacketMailed1: "datePacketMailed1",
  datePacketMailed2: "datePacketMailed2",
  datePacketMailed3: "datePacketMailed3",
  datePacketMailed4: "datePacketMailed4",
  datePacketMailed5: "datePacketMailed5",
  datePacketMailed6: "datePacketMailed6",
  datePacketMailed7: "datePacketMailed7",
  datePacketMailed8: "datePacketMailed8",
  datePacketMailed9: "datePacketMailed9",
};

export const TAX_SETTLEMENT_FIELDS_V1 = {
  amountDue: "payOffAmount",
  irsAccount: "irsAccountNum",
  taxYear: "taxYear",
  mailingDate2: "mailingDate2",
  irsTechCertifiedMailNumber: "irsTechDeptCertifiedMailNum",
  treasuryDeptCertifiedMailNumber: "treasuryDeptCertifiedMailNum",
  treasurySectaryCertifiedMailNumber: "treasurySecretaryCertifiedMailNum",
  irsPublicCertifiedMailNumber: "irsPubCertifiedMailNum",
  irsPrivateRegisteredMailNumber: "irsPriRegisteredMailNum",
  treasuryRegisteredMailNumber: "treasuryRegisteredMailNum",
  irsPublicAddress: "irsPubAddr",
  irsPublicCity: "irsPubCity",
  irsPublicState: "irsPubState",
  irsPublicZip: "irsPubZipCode",
  irsPuName: "irsPubDepartName",
  irsPrivateAddress: "irsPriAddr",
  irsPrivateCity: "irsPriCity",
  irsPrivateState: "irsPriState",
  irsPrivateZip: "irsPriZipCode",
  irsPName: "irsPriDepartName",
  notaryAddress: "notaryAddr",
  notaryName: "notaryName",
  notaryCity: "notaryCity",
  notaryState: "notaryState",
  notaryZip: "notaryZip",
};

export const TAX_REVIEW_PRODUCT_STATUS_LIST = [TaxProductStatus.REVIEW, TaxProductStatus.REJECTED];

export const TAX_READY_PRODUCT_STATUS_LIST = [
  TaxProductStatus.UCC,
  TaxProductStatus.ACCEPTED,
  TaxProductStatus.DOWNLOADED,
  TaxProductStatus.DELIVERED,
];
export const TAX_SETTLEMENT_APPROVAL_STATUS = [
  TaxProductStatus.REVIEW,
  TaxProductStatus.DOC_1_SIGNATURE_REVIEW,
  TaxProductStatus.DATA_2_REVIEW,
  TaxProductStatus.DOC_2_SIGNATURE_REVIEW,
];

export const TAX_SETTLEMENT_DOCS = [
  {
    value: TaxDocNames.NEW_TAX,
    label: "Tax Settlement Docs",
  },
  {
    value: TaxDocNames.EXECUTED_TAX_PACKET,
    label: "Executed Tax Packet",
  },
  {
    value: TaxDocNames.BOE_ATTACHMENT_A,
    label: "BOE Attachment A",
  },
  {
    value: TaxDocNames.TAX_PRIVATE_BILL_OF_EXCHANGE,
    label: "Tax Private Bill Of Exchange",
  },
  {
    value: TaxDocNames.BOE_ATTACHMENT_B,
    label: "BOE Attachment B",
  },
  {
    value: TaxDocNames.TAX_OF_BILL_EXCHANGE,
    label: "BOE UCC-1 Filing",
  },
  {
    value: TaxDocNames.PIB_ATTACHMENT_A,
    label: "PIB Attachment A",
  },
  {
    value: TaxDocNames.TAX_PRIVATE_INDEMNITY_BOND,
    label: "Tax Private Indemnity Bond",
  },
  {
    value: TaxDocNames.PIB_ATTACHMENT_B,
    label: "PIB Attachment B",
  },
  {
    value: TaxDocNames.TAX_SETTLEMENT_BOND,
    label: "PIB UCC-1 Filing",
  },
  {
    value: TaxDocNames.F1040ES,
    label: "Tax F1040ES",
  },
  {
    value: TaxDocNames.F1040V,
    label: "Tax 1040V",
  },
  {
    value: TaxDocNames.DOC_2,
    label: "DOC 2",
  },
  {
    value: TaxDocNames.CERT_OF_MAILINGS,
    label: "Certificate of M",
  },
  {
    value: TaxDocNames.BOE_TREASURY_COVER_LETTER_SIGNED,
    label: "Tax T BOE Cover",
  },
  {
    value: TaxDocNames.BIP_TREASURY_COVER_LETTER_SIGNED,
    label: "Tax T PIB Cover",
  },
  {
    value: TaxDocNames.CERT_OF_MAILING_IRS_TECH,
    label: "Cert.M Technical",
  },
  {
    value: TaxDocNames.CERT_OF_MAILING_TTB,
    label: "Cert.M Excise",
  },
  {
    value: TaxDocNames.CERT_OF_MAILING_SEC,
    label: "Cert.M Treas Sec",
  },
  {
    value: TaxDocNames.CERT_OF_MAILING_IRS_P,
    label: "Cert.M Private",
  },
  {
    value: TaxDocNames.CERT_OF_MAILING_IRS_PU,
    label: "Cert.M Public",
  },
  {
    value: TaxDocNames.MASTER_TAX_UCC,
    label: "Master Tax UCC",
  },
  {
    value: TaxDocNames.BOE_SIGNED,
    label: "BOE Executed",
  },
  {
    value: TaxDocNames.PIB_SIGNED,
    label: "PIB Executed",
  },
  {
    value: TaxDocNames.TAX_TRACKING_RECEIPTS,
    label: "Tracking Receipts",
  },
  {
    value: TaxDocNames.TAX_PROD_RECEIPTS,
    label: "POD Documents",
  },
  {
    value: TaxDocNames.NEW_TAX_2,
    label: "Regenerated Tax Docs",
  },
  {
    value: TaxDocNames.TAX_UCC_REPORT_1,
    label: "Tax UCC Report-1",
  },
  {
    value: TaxDocNames.TAX_UCC_REPORT_2,
    label: "Tax UCC Report-2",
  },
  {
    value: TaxDocNames.TAX_UCC_REPORT_3,
    label: "Tax UCC Report-3",
  },
];
export const DELETE_SETTLEMENT_DOCS = [
  {
    value: TaxDocNames.EXECUTED_TAX_PACKET,
    label: "Executed Tax Packet",
  },
  {
    value: TaxDocNames.BOE_TREASURY_COVER_LETTER_SIGNED,
    label: "Tax T BOE Cover",
  },
  {
    value: TaxDocNames.BIP_TREASURY_COVER_LETTER_SIGNED,
    label: "Tax T PIB Cover",
  },
  {
    value: TaxDocNames.CERT_OF_MAILING_IRS_TECH,
    label: "Cert.M Technical",
  },
  {
    value: TaxDocNames.CERT_OF_MAILING_TTB,
    label: "Cert.M Excise",
  },
  {
    value: TaxDocNames.CERT_OF_MAILING_SEC,
    label: "Cert.M Treas Sec",
  },
  {
    value: TaxDocNames.CERT_OF_MAILING_IRS_P,
    label: "Cert.M Private",
  },
  {
    value: TaxDocNames.CERT_OF_MAILING_IRS_PU,
    label: "Cert.M Public",
  },
  {
    value: TaxDocNames.BOE_SIGNED,
    label: "BOE Executed",
  },
  {
    value: TaxDocNames.PIB_SIGNED,
    label: "PIB Executed",
  },
  {
    value: TaxDocNames.TAX_TRACKING_RECEIPTS,
    label: "Tracking Receipts",
  },
  {
    value: TaxDocNames.TAX_PROD_RECEIPTS,
    label: "POD Documents",
  },
  {
    value: TaxDocNames.NEW_TAX_2,
    label: "Regenerated Tax Docs",
  },
];

export const REGENERATE_SETTLEMENT_DOC = [
  {
    value: TaxDocNames.NEW_TAX,
    label: "Tax Settlement Docs",
  },
  {
    value: TaxDocNames.BOE_ATTACHMENT_A,
    label: "BOE Attachment A",
  },
  {
    value: TaxDocNames.TAX_PRIVATE_BILL_OF_EXCHANGE,
    label: "Tax Private Bill Of Exchange",
  },
  {
    value: TaxDocNames.BOE_ATTACHMENT_B,
    label: "BOE Attachment B",
  },
  {
    value: TaxDocNames.TAX_OF_BILL_EXCHANGE,
    label: "BOE UCC-1 Filing",
  },
  {
    value: TaxDocNames.PIB_ATTACHMENT_A,
    label: "PIB Attachment A",
  },
  {
    value: TaxDocNames.TAX_PRIVATE_INDEMNITY_BOND,
    label: "Tax Private Indemnity Bond",
  },
  {
    value: TaxDocNames.PIB_ATTACHMENT_B,
    label: "PIB Attachment B",
  },
  {
    value: TaxDocNames.TAX_SETTLEMENT_BOND,
    label: "PIB UCC-1 Filing",
  },
  {
    value: TaxDocNames.CERT_OF_MAILINGS,
    label: "Certificate of M",
  },
  {
    value: TaxDocNames.F1040ES,
    label: "Tax F1040ES",
  },
  {
    value: TaxDocNames.F1040V,
    label: "Tax 1040V",
  },
  {
    value: TaxDocNames.NEW_TAX_2,
    label: "Regenerated Tax Docs",
  },
];

export const SHOW_MERGE_FIELD = [
  {
    label: "BOE UCC-1 Filing",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.TAX_BILL_EXCHANGE,
    },
  },
  {
    label: "PIB UCC-1 Filing",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.TAX_SETTLEMENT_BOND,
    },
  },
  {
    label: "Master Tax UCC",
    value: {
      sku: Sku.TAX_V2_DOCX,
      productName: TemplateProductName.MASTER_TAX_UCC,
    },
  },
];

export const TAX_EMAIL_TYPES = [
  {
    value: "TaxSettlementReviewRejected",
    label: "Tax Settlement Rejected",
  },
  {
    value: "TaxSettlementInProcess",
    label: "Tax Settlement in Process",
  },
  {
    value: "TaxSettlementSubmittedToUCC",
    label: "Tax Settlement Submitted to UCC",
  },
  {
    value: "TaxSettlementReadyForSignature",
    label: "Tax Settlement Signature Required",
  },
  {
    value: "TaxSettlementSignatureReviewRejected",
    label: "Tax Settlement Signature Rejected",
  },
  {
    value: "TaxSettlementCompleted",
    label: "Tax Settlement Completed",
  },
];
