<nav #navbar class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          mat-raised-button
          (click)="minimizeSidebar()"
          class="btn btn-just-icon btn-white btn-fab btn-round"
        >
          <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </button>
      </div>
    </div>
    <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#pablo"
            id="notification-dropdown-menu"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="material-icons">notifications</i>
            <span class="notification">{{ totalReviewsCnt }}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="notification-dropdown-menu">
            <div *ngFor="let product of dashboardFolders">
              <div *ngFor="let folder of product.folders">
                <a
                  *ngIf="getFolderCount(product.sku, folder.status)"
                  class="dropdown-item"
                  href="#"
                  (click)="onCardClick($event, folder.url, folder.status)"
                >
                  {{ getFolderCount(product.sku, folder.status) }} - {{ folder.title }}
                </a>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#pablo"
            id="account-dropdown-menu"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="material-icons">person</i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="account-dropdown-menu">
            <a class="dropdown-item" href="#">{{ user?.email }}</a>
            <a class="dropdown-item" href="#" (click)="onLogout($event)">
              <i class="material-icons mr-2">exit_to_app</i>
              Log Out
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
