<h3 mat-dialog-title class="text-center">Comment</h3>
<div mat-dialog-content class="mt-2 mb-2">
  <div class="row">
    <form class="col-12" [formGroup]="commentForm">
      <div class="row">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label> Comment </mat-label>
          <textarea matInput [rows]="4" formControlName="comment" placeholder="Comment" required></textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-around align-items-center">
  <button type="button" class="btn btn-danger btn-round text-capitalize" (click)="onClose()">Close</button>
  <button type="button" class="btn btn-success btn-round text-capitalize" (click)="onSubmit()">Submit</button>
</div>
