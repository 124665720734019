<div class="row bg-white" [style.width.px]="width">
  <div
    class="col-12 d-flex align-items-center"
    style="background-color: #323639 !important; height: 56px !important"
  >
    <span class="f-16-500 text-white"> {{ doc.filename }} </span>
    <button
      type="button"
      class="btn btn-warning btn-round btn-fab btn-sm ml-auto mr-1"
      (click)="onDownloadDoc()"
      [disabled]="!isDocLoaded"
    >
      <i class="material-icons">vertical_align_bottom</i>
    </button>
    <button type="button" class="btn btn-danger btn-round btn-fab btn-sm ml-1" matRipple (click)="onClose()">
      <i class="material-icons">close</i>
    </button>
  </div>

  <div class="col-12 my-3 overflow-auto" [style.height.px]="height">
    <!-- <ngx-doc-viewer [url]="docSrc" viewer="mammoth" style="width: 100%; height: 100%"></ngx-doc-viewer> -->
    <div id="container" class="doc-preview"></div>
  </div>
</div>
